// src/routes/index.js

import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

// Lazy load all pages
const HomePage = lazy(() => import("../pages/HomePage"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const RegisterPage = lazy(() => import("../pages/RegisterPage"));
const ForgotPasswordPage = lazy(() => import("../pages/ForgotPasswordPage"));
const FindFundingPage = lazy(() => import("../pages/FindFundingPage"));
const ResearchToolsPage = lazy(() => import("../pages/ResearchToolsPage"));
const CollaborationPage = lazy(() => import("../pages/CollaborationPage"));
const PostOpportunitiesPage = lazy(() =>
  import("../pages/PostOpportunitiesPage")
);
const ManageApplicationsPage = lazy(() =>
  import("../pages/ManageApplicationsPage")
);
const AnalyticsPage = lazy(() => import("../pages/AnalyticsPage"));
const AboutUsPage = lazy(() => import("../pages/AboutUsPage"));
const TermsOfServicePage = lazy(() => import("../pages/TermsOfServicePage"));
const PrivacyPolicyPage = lazy(() => import("../pages/PrivacyPolicyPage"));
const GDPRPage = lazy(() => import("../pages/GDPRPage"));
const ImprintPage = lazy(() => import("../pages/ImprintPage"));
const ContactUsPage = lazy(() => import("../pages/ContactUsPage"));
const SitemapPage = lazy(() => import("../pages/SitemapPage"));
const AdminPage = lazy(() => import("../pages/AdminPage"));
const LLMJsonPage = lazy(() => import("../pages/LLMJsonPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const WeaviateDebugPage = lazy(() => import("../pages/WeaviateDebugPage"));

// PrivateRoute component to protect routes that require authentication
const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
};

// LegalRoutes component for grouping legal-related routes
const LegalRoutes = () => (
  <Routes>
    <Route path="terms-of-service" element={<TermsOfServicePage />} />
    <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
    <Route path="gdpr" element={<GDPRPage />} />
    <Route path="imprint" element={<ImprintPage />} />
  </Routes>
);

// Main AppRoutes component
const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/legal/*" element={<LegalRoutes />} />
          <Route path="/sitemap" element={<SitemapPage />} />

          {/* Protected routes */}
          <Route
            path="/find-funding"
            element={
              <PrivateRoute>
                <FindFundingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/research-tools"
            element={
              <PrivateRoute>
                <ResearchToolsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/collaboration"
            element={
              <PrivateRoute>
                <CollaborationPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/post-opportunities"
            element={
              <PrivateRoute>
                <PostOpportunitiesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-applications"
            element={
              <PrivateRoute>
                <ManageApplicationsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <PrivateRoute>
                <AnalyticsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />

          {/* Debug routes (consider protecting or removing in production) */}
          <Route path="/llmJson" element={<LLMJsonPage />} />
          <Route path="/weaviate-debug" element={<WeaviateDebugPage />} />

          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
