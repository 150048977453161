// styles/GlobalStyles.js

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
    line-height: 1.5;
    
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  body {
    font-family: ${(props) => props.theme.fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  main {
    flex: 1;
    width: 100%;
    padding-top: 3.5rem; // Adjust this value based on your header height
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover, &:focus {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button, input[type="submit"] {
    cursor: pointer;
  }

  :focus {
    outline: 3px solid ${(props) => props.theme.colors.focus};
    outline-offset: 2px;
  }

  ::selection {
    background-color: ${(props) => props.theme.colors.selection};
    color: ${(props) => props.theme.colors.text};
  }

  /* Responsive typography */
  h1 {
    font-size: 2.5rem;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 2rem;
    
    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  h3 {
    font-size: 1.75rem;
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  /* Improve touch target sizes for mobile */
  @media (max-width: 768px) {
    button, input[type="submit"], a {
      min-height: 44px;
      min-width: 44px;
    }
  }
`;

export default GlobalStyles;
