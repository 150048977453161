// src/contexts/AuthContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to sign up a new user
  async function signup(email, password, additionalData) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await updateProfile(user, {
        displayName: `${additionalData.firstName} ${additionalData.lastName}`,
      });
      await setUserData(user.uid, { ...additionalData, email: user.email });
      await sendEmailVerification(user);
      return user;
    } catch (error) {
      console.error("Error in signup:", error);
      throw error;
    }
  }

  // Function to log in a user
  async function login(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      if (!user.emailVerified) {
        return { user, verified: false };
      }
      return { user, verified: true };
    } catch (error) {
      console.error("Error in login:", error);
      throw error;
    }
  }

  // Function to log out a user
  function logout() {
    return signOut(auth);
  }

  // Function to set user data in Firestore
  async function setUserData(uid, data) {
    const userRef = doc(db, "users", uid);
    await setDoc(userRef, { ...data, createdAt: new Date() }, { merge: true });
  }

  // Function to get user data from Firestore
  async function getUserData(user) {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        return { ...user, ...userSnap.data() };
      } else {
        const userData = {
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          createdAt: new Date(),
        };
        await setDoc(userRef, userData);
        return { ...user, ...userData };
      }
    }
    return null;
  }

  // Function to sign in with Google
  async function googleSignIn() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await getUserData(user);
      return user;
    } catch (error) {
      console.error("Error in Google Sign In:", error);
      throw error;
    }
  }

  // Function to reset password
  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  // Function to resend verification email
  async function resendVerificationEmail() {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      try {
        await sendEmailVerification(auth.currentUser);
        return {
          success: true,
          message: "Verification email sent successfully.",
        };
      } catch (error) {
        console.error("Error sending verification email:", error);
        return {
          success: false,
          message: "Failed to send verification email. Please try again.",
        };
      }
    } else {
      return {
        success: false,
        message: "No user found or email already verified.",
      };
    }
  }

  // New function to update user profile
  async function updateUserProfile(profileData) {
    try {
      if (!auth.currentUser) {
        throw new Error("No authenticated user found");
      }

      // Update Firebase Auth profile
      await updateProfile(auth.currentUser, {
        displayName: profileData.displayName,
        photoURL: profileData.photoURL,
      });

      // Update Firestore document
      const userRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userRef, profileData);

      // Update current user state
      const updatedUserData = await getUserData(auth.currentUser);
      setCurrentUser(updatedUserData);

      return { success: true, message: "Profile updated successfully." };
    } catch (error) {
      console.error("Error updating user profile:", error);
      return {
        success: false,
        message: "Failed to update profile. Please try again.",
      };
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const userData = user ? await getUserData(user) : null;
      setCurrentUser(userData);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    googleSignIn,
    resetPassword,
    resendVerificationEmail,
    updateUserProfile,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
