// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Get the root element from the HTML
const rootElement = document.getElementById("root");

// Create a root using createRoot API
const root = ReactDOM.createRoot(rootElement);

// Render the App component within React.StrictMode
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Report web vitals for performance monitoring
reportWebVitals();
