// styles/theme/darkTheme.js

export const darkTheme = {
  colors: {
    primary: "#7986CB", // Light Indigo: Main brand color, lighter for dark theme visibility
    primaryLight: "#9FA8DA", // Very Light Indigo: Used for hover states or backgrounds
    primaryDark: "#5C6BC0", // Indigo: Used for active states or text
    primaryRGB: "121, 134, 203", // Light Indigo (RGB): Useful for rgba() color definitions

    secondary: "#FFB74D", // Light Orange: Secondary brand color, lighter for dark theme visibility
    secondaryLight: "#FFCC80", // Very Light Orange: Used for hover states or backgrounds
    secondaryDark: "#FFA726", // Orange: Used for active states or text
    secondaryRGB: "255, 183, 77", // Light Orange (RGB): Useful for rgba() color definitions

    background: "#121212", // Very Dark Gray: Main background color for dark mode
    text: "#E0E0E0", // Light Gray: Primary text color for dark mode
    textLight: "#BDBDBD", // Light Gray (slightly darker): Secondary text color, for less emphasized text
    textInverted: "#333333", // Dark Gray: Text color for use on light backgrounds within dark mode

    accent1: "#424242", // Dark Gray: Used for backgrounds or borders
    accent2: "#689F38", // Olive Green: Alternative accent, adjusted for dark mode visibility

    success: "#66BB6A", // Light Green: For success messages or indicators in dark mode
    successRGB: "102, 187, 106", // Light Green (RGB): Useful for rgba() color definitions
    warning: "#FFA000", // Amber: For warning messages or indicators in dark mode
    error: "#E57373", // Light Red: For error messages or indicators in dark mode
    errorRGB: "229, 115, 115", // Light Red (RGB): Useful for rgba() color definitions
    errorDark: "#C62828", // Dark Red: For emphasis or hover states in error contexts
    info: "#64B5F6", // Light Blue: For informational messages or indicators in dark mode

    white: "#212121", // Very Dark Gray: Used for contrasts in dark mode
    footerBackground: "#000000", // Black: Footer background color in dark mode
    highlight: "rgba(255, 255, 0, 0.3)", // Yellow (with opacity): For text highlights in dark mode
    selection: "rgba(121, 134, 203, 0.3)", // Light Indigo (with opacity): For selected text in dark mode
    border: "rgba(255, 255, 255, 0.2)", // White (with opacity): Default border color in dark mode
    orange: "#fd7e14", // Dark Orange: Can be used for notifications or special highlights
    cardBackground: "#1E1E1E", // Very Dark Gray: Background color for card elements in dark mode
  },
  fonts: {
    heading: "Montserrat, sans-serif", // Font family for headings
    body: "Open Sans, sans-serif", // Font family for body text
  },
  shadows: {
    small: "0 2px 4px rgba(0, 0, 0, 0.3)",
    medium: "0 4px 6px rgba(0, 0, 0, 0.3)",
    large: "0 10px 15px rgba(0, 0, 0, 0.3)",
    hover: "0 10px 20px rgba(0, 0, 0, 0.4)",
    focus: "0 0 15px rgba(121, 134, 203, 0.5)",
    icon: "0 4px 30px rgba(121, 134, 203, 0.2)",
    iconHover: "0 4px 30px rgba(121, 134, 203, 0.3)",
  },
};
