// src/App.js

import React from "react";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider } from "./contexts/AuthContext";
import Routes from "./routes";

function App() {
  return (
    // ThemeProvider wraps the entire app to provide theme context
    <ThemeProvider>
      {/* AuthProvider wraps the app to provide authentication context */}
      <AuthProvider>
        {/* Routes component contains all the routing logic */}
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
