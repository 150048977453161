// styles/theme/lightTheme.js

export const lightTheme = {
  colors: {
    primary: "#3F51B5", // Indigo: Main brand color, used for primary actions and highlights
    primaryLight: "#7986CB", // Light Indigo: Used for hover states or backgrounds
    primaryDark: "#303F9F", // Dark Indigo: Used for active states or text
    primaryRGB: "63, 81, 181", // Indigo (RGB): Useful for rgba() color definitions

    secondary: "#FF9800", // Orange: Secondary brand color, used for accents and secondary actions
    secondaryLight: "#FFB74D", // Light Orange: Used for hover states or backgrounds
    secondaryDark: "#F57C00", // Dark Orange: Used for active states or text
    secondaryRGB: "255, 152, 0", // Orange (RGB): Useful for rgba() color definitions

    background: "#FFFFFF", // White: Main background color
    text: "#333333", // Dark Gray: Primary text color
    textLight: "#757575", // Medium Gray: Secondary text color, for less emphasized text
    textInverted: "#FFFFFF", // White: Text color for use on dark backgrounds

    accent1: "#E0E0E0", // Light Gray: Used for backgrounds or borders
    accent2: "#8BC34A", // Light Green: Alternative accent, for success states or nature-themed elements

    success: "#4CAF50", // Green: For success messages or indicators
    successRGB: "76, 175, 80", // Green (RGB): Useful for rgba() color definitions
    warning: "#FFC107", // Amber: For warning messages or indicators
    error: "#F44336", // Red: For error messages or indicators
    errorRGB: "244, 67, 54", // Red (RGB): Useful for rgba() color definitions
    errorDark: "#D32F2F", // Dark Red: For emphasis or hover states in error contexts
    info: "#2196F3", // Blue: For informational messages or indicators

    white: "#FFFFFF", // White: Used for contrasts or overlays
    footerBackground: "rgba(245, 247, 250, 0.7)", // Very Light Gray (with opacity): For footer or overlays
    highlight: "rgba(255, 255, 0, 0.3)", // Yellow (with opacity): For text highlights
    selection: "rgba(0, 123, 255, 0.3)", // Light Blue (with opacity): For selected text
    border: "rgba(0, 0, 0, 0.1)", // Black (with opacity): Default border color
    orange: "#fd7e14", // Dark Orange: Can be used for notifications or special highlights
    cardBackground: "#FFFFFF", // White: Background color for card elements
  },
  fonts: {
    heading: "Montserrat, sans-serif", // Font family for headings
    body: "Open Sans, sans-serif", // Font family for body text
  },
  shadows: {
    small: "0 2px 4px rgba(0, 0, 0, 0.1)",
    medium: "0 4px 6px rgba(0, 0, 0, 0.1)",
    large: "0 10px 15px rgba(0, 0, 0, 0.1)",
    hover: "0 10px 20px rgba(0, 0, 0, 0.2)",
    focus: "0 0 15px rgba(0, 123, 255, 0.5)",
    icon: "0 4px 30px rgba(63, 81, 181, 0.1)",
    iconHover: "0 4px 30px rgba(63, 81, 181, 0.2)",
  },
};
